import produce from "immer";
import { IAppState, Task } from "../types";
import * as API from "../api";

/**
 * Delete a todo :-)
 * 
 * Removes task from `appState`, deletes from API and returns updated `appState`.
 */
export const deleteTodo = (
  appState: IAppState,
  columnId: string,
  taskId: string,
  deleteTask: (task: Task) => void = API.deleteTask
) => {
  return produce<IAppState>(appState, newState => {
    deleteTask(newState.tasks[taskId]);
    delete newState.tasks[taskId];
    newState.columns[columnId].taskIds = newState.columns[
      columnId
    ].taskIds.filter(item => item !== taskId);
    return newState;
  });
};
