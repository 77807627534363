import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "@atlaskit/css-reset";

import styled from "styled-components";

import { DragDropContext, DropResult } from "react-beautiful-dnd";

import * as serviceWorker from "./serviceWorker";

import { Column } from "./components/Column";
import { AppState, useAppState } from "./appState";
import { Actions } from "./types";
import { Button } from "./components/Button";
import { taskVibesToVibe } from "./util/taskVibesToVibe";
import { vibeToColour } from "./util/vibeToColour";
import { getWeek } from "./api/getWeek";

const Container = styled.div`
  width: 100vw;
`;

const FixedFooter = styled.div<{ overallVibe: number | undefined }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  height: 60px;
  background: ${(props) => vibeToColour(props.overallVibe ?? 1)};
  bottom: 0px;
  z-index: 999;
  border-top: 3px solid #8b1fb7;
`;

const ColumnList = styled.div`
  overflow-y: scroll;
  padding-bottom: ${61}px;
`;

async function changeWeek(
  dispatch: (value: Actions) => void,
  currentWeek: Date
) {
  const week = await getWeek({
    anyDayInTheWeek: currentWeek,
  });
  dispatch({ type: "changeWeek", week });
}
//

const Loading = () => {
  return <div>Loading...</div>;
};

const App: React.FC = () => {
  const { dispatch, state } = useAppState();
  const [currentWeek, setCurrentWeek] = useState(new Date());

  useEffect(() => {
    dispatch({ type: "loading", loading: true });
    changeWeek(dispatch, currentWeek);
  }, [dispatch, currentWeek]);

  const handleNextWeek = () => setCurrentWeek(state.nextWeek);
  const handlePreviousWeek = () => setCurrentWeek(state.previousWeek);
  const handleToday = () => setCurrentWeek(new Date());

  const onDragEnd = ({ destination, source, draggableId }: DropResult) => {
    dispatch({ type: "moveTodo", destination, source, draggableId });
  };

  const overallVibe = taskVibesToVibe(Object.values(state.tasks));

  if (state.loading) {
    return (
      <div>
        <Loading />;
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <ColumnList>
          {state.columnOrder.map((columnId) => {
            const column = state.columns[columnId];
            const tasks = column.taskIds.map((taskId) => state.tasks[taskId]);
            return <Column key={column.id} column={column} tasks={tasks} />;
          })}
        </ColumnList>
        <FixedFooter overallVibe={overallVibe}>
          <Button small primary onClick={handlePreviousWeek}>
            Last
          </Button>
          <Button small primary onClick={handleToday}>
            This Week
          </Button>
          <Button small primary onClick={handleNextWeek}>
            Next
          </Button>
        </FixedFooter>
      </Container>
    </DragDropContext>
  );
};

ReactDOM.render(
  <AppState>
    <App />
  </AppState>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
