import React from "react";

/**
 * Get a suitable vibe icon based vibe, size, highlight.
 * 
 * Add an onClick handler if needed.
 */
export const VibeIcon: React.FC<{
  vibe: number;
  size: number;
  highlight?: boolean;
  onClick?: () => void;
}> = ({ vibe, size, highlight, onClick = () => {} }) => {
  function vibeToIcon(vibe: number) {
    const increment = 1 / 7;
    let icon = "0";
    if (vibe > 0 && vibe <= increment * 1) icon = "1";
    if (vibe > increment * 1 && vibe <= increment * 2) icon = "2";
    if (vibe > increment * 2 && vibe <= increment * 3) icon = "3";
    if (vibe > increment * 3 && vibe <= increment * 4) icon = "4";
    if (vibe > increment * 4 && vibe <= increment * 5) icon = "5";
    if (vibe > increment * 5 && vibe <= increment * 6) icon = "6";
    if (vibe > increment * 6 && vibe <= 1) icon = "7";
    return icon;
  }
  return (
    <img
      alt="Vibe Icon"
      onClick={onClick}
      style={{
        minHeight: 20,
        height: highlight? 80 * size : 60 * size,
        marginRight: size < 0.4 ? 5 : 10 * size,
        cursor: "pointer"
      }}
      src={`vibe-${vibeToIcon(vibe)}.svg`}
    />
  );
};
