import { startOfWeek, endOfWeek, addDays } from "date-fns";

/**
 * Get the start end etc of a week given a day in that week.
 * 
 * `weekStartsOn` number is the day the week starts, 1 = Sunday, 2 = Monday, etc.
 */
export const setUpWeekDates = ({
  anyDayInTheWeek,
  weekStartsOn
}: {
  anyDayInTheWeek: Date;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
}) => {
  const start = startOfWeek(anyDayInTheWeek, { weekStartsOn });
  const end = endOfWeek(anyDayInTheWeek, { weekStartsOn });
  const previousWeek = addDays(start, -1);
  const nextWeek = addDays(end, 1);
  return { start, end, previousWeek, nextWeek };
};
