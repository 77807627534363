import produce from "immer";
import uuidv4 from "uuid/v4";
import { IAppState, Task } from "../types";
import { parse } from "date-fns";
import { calculateLexicographicalOrdering } from "./calculateLexicographicOrdering";

/**
 * Add a **ephemeral**, new task to a given `columnId` in the current `appState`. Returns
 * the updated `appState`.
 *
 * **NOTE:** we *don't* upsert the task using the API. That happens during upsertTodo.
 * However, `new` is set to true on the task so you can check to see if it is an ephemeral task.
 *
 * @param appState
 * @param columnId
 * @param content
 * @param size
 * @param vibe
 */
export const addTodo = (
  appState: IAppState,
  columnId: string,
  content: string,
  size: number,
  vibe: number
) => {
  const newTaskId = uuidv4();
  const dateTimeISOUTC = parse(columnId, "yyyyMMdd", new Date()).getTime();

  const newTask: Task = {
    id: newTaskId,
    content,
    procrastination: 0,
    size,
    vibe,
    ratings: [],
    moves: [{createdAt: new Date().getTime(), to: dateTimeISOUTC}],
    new: true, // the task is ephemeral
    completed: false,
    lexicographicalOrdering: "TBD",
    dateTimeISOUTC // ms since epoch
  };

  // NOTE: we don't save the task to the storage API. We do that during upsertTodo.

  return produce<IAppState>(appState, newState => {
    newState.columns[columnId].taskIds.unshift(newTaskId); // do this BEFORE calcing lexicographical ordering
    const lexicographicalOrdering = calculateLexicographicalOrdering(
      newState,
      newTask.id
    );
    newTask.lexicographicalOrdering = lexicographicalOrdering;
    newState.tasks[newTaskId] = newTask;
    return newState;
  });
};
