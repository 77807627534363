import produce from "immer";
import { IAppState, Task } from "../types";
import * as API from "../api";

/**
 * Updates a given task. Replaces the existing task in `appState`. Upserts
 * the change to API and returns the updatd `appState`.
 *
 * **NOTE**: Sets `new` property of task to `false`.
 */
export const upsertTodo = (
  appState: IAppState,
  taskId: string,
  content: string,
  size: number,
  vibe: number,
  completed: boolean,
  upsertTask: (task: Task) => void = API.upsertTask
) => {
  return produce<IAppState>(appState, (newState) => {
    const oldVersion = newState.tasks[taskId];
    let newVersion = {
      ...oldVersion,
      new: false,
      content,
      size,
      vibe,
      completed,
    };

    // If completed is being set to false, reset any "after" completion ratings.
    // These reset if the user sets the task from completed to incomplete
    if (
      oldVersion.ratings.length > 0 &&
      oldVersion.completed &&
      !newVersion.completed
    ) {
      newVersion = {
        ...newVersion,
        ratings: [{ createdAt: new Date().getTime(), size, vibe }],
      };
    }

    newState.tasks[taskId] = newVersion;
    upsertTask(newVersion);

    return newState;
  });
};
