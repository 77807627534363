import { format, eachDayOfInterval, isSameDay } from "date-fns";
import { setUpWeekDates } from "../appState/setUpWeekDates";
import { getTasks } from ".";
import {
  getWeek as getWeekType,
  Task,
  Columns,
  IAppState,
  Tasks,
} from "../types";

/**
 * Get the current week's state. Defaults to current week.
 */
export const getWeek: getWeekType = async ({
  getTasksWithinDateTimeRange = getTasks,
  anyDayInTheWeek = new Date(),
  weekStartsOn = 1,
}) => {
  const { start, end, previousWeek, nextWeek } = setUpWeekDates({
    anyDayInTheWeek,
    weekStartsOn,
  });

  const dayIds = eachDayOfInterval({ start, end }).map((day) => ({
    id: format(day, "yyyyMMdd"),
    date: day,
  }));

  const currentWeeksTasksFromAPI = await getTasksWithinDateTimeRange({
    start,
    end,
  });

  // Split up tasks into days:
  const tasksBylocalTimeZoneDay = currentWeeksTasksFromAPI.tasks.reduce<{
    [key: string]: Task[];
  }>((acc, task) => {
    const zonedDate = new Date(task.dateTimeISOUTC);
    const dayId = format(zonedDate, "yyyyMMdd");
    acc[dayId] ? acc[dayId].push(task) : (acc[dayId] = [task]);
    return acc;
  }, {});

  const today = new Date();

  const columns = dayIds.reduce<Columns>((acc, day) => {
    // id of column is a string of format yyyyMMdd.

    const isToday = isSameDay(day.date, today);

    // Get the date:
    const column = {
      id: day.id,
      date: day.date,
      title: isToday
        ? `${format(day.date, "EEE MMM do")} (Today!)`
        : format(day.date, "EEE MMM do"),
      taskIds: tasksBylocalTimeZoneDay[day.id]
        ? tasksBylocalTimeZoneDay[day.id].map((task) => task.id)
        : [],
    };
    acc[day.id] = column;
    return acc;
  }, {});

  const nextState: IAppState = {
    loading: false,
    start,
    end,
    previousWeek,
    nextWeek,
    previousTask: currentWeeksTasksFromAPI.previousTask,
    nextTask: currentWeeksTasksFromAPI.nextTask,
    tasks: currentWeeksTasksFromAPI.tasks.reduce<Tasks>((acc, task) => {
      acc[task.id] = task;
      return acc;
    }, {}),
    columnOrder: dayIds.map((day) => day.id),
    columns,
  };
  return nextState;
};
