import React, { createContext, useContext, useReducer } from "react";
import { IAppState } from "../types";
import { getInitialData } from "./getInitialData";
import { moveTodo } from "./moveTodo";
import { deleteTodo } from "./deleteTodo";
import { upsertTodo } from "./upsertTodo";
import { addTodo } from "./addTodo";
import { Actions } from "../types";
import produce from "immer";
import { afterRateTodo } from "./afterRateTodo";
import UIFx from "uifx";

const yaay = new UIFx("/yaay3.mp3", {
  volume: 1, // number between 0.0 ~ 1.0
  throttleMs: 100,
});

const boo = new UIFx("/boo.mp3", {
  volume: 1, // number between 0.0 ~ 1.0
  throttleMs: 100,
});

const reducer: React.Reducer<IAppState, Actions> = (
  state: IAppState,
  action: Actions
) => {
  switch (action.type) {
    case "changeWeek":
      return action.week;
    case "createTodo":
      return addTodo(
        state,
        action.columnId,
        action.content,
        action.size,
        action.vibe
      );
    case "updateTodo":
      return upsertTodo(
        state,
        action.taskId,
        action.content,
        action.size,
        action.vibe,
        action.completed
      );
    case "afterRateTodo":
      return afterRateTodo(state, action.taskId, action.size, action.vibe);
    case "deleteTodo":
      return deleteTodo(state, action.columnId, action.taskId);
    case "moveTodo":
      return (
        moveTodo(
          state,
          action.destination,
          action.source,
          action.draggableId
        ) ?? state
      );
    case "playAudio":
      return produce<IAppState>(state, (newState) => {
        if (action.audio?.name === "yaay") {
          yaay.play();
        }
        if (action.audio?.name === "boo") {
          boo.play();
        }
        return newState;
      });
    case "loading":
      return produce<IAppState>(state, (newState) => {
        newState.loading = action.loading;
        return newState;
      });
    default:
      return state;
  }
};

const initialContext: {
  state: IAppState;
  loading: boolean;
  dispatch: React.Dispatch<Actions>;
} = {
  state: {} as IAppState, // FIXME: ugly
  loading: true,
  dispatch: () => {},
};

const Context = createContext(initialContext);

/**
 * Wraps children in App State context provider
 */
export const AppState: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialData());
  return (
    <Context.Provider value={{ state, loading: true, dispatch }}>
      {children}
    </Context.Provider>
  );
};

/**
 * Access the app state context.
 */
export const useAppState = () => useContext(Context);
