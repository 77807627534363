import React from "react";
import { VibeIcon } from "./VibeIcon";
export const VibeSelector: React.FC<{
  vibe: number;
  onChange: (vibe: number) => void;
  iconSize: number;
}> = ({ onChange, iconSize, vibe }) => {
  return (
    <div>
      <VibeIcon
        onClick={() => onChange(1)}
        vibe={1}
        size={iconSize}
        highlight={vibe === 1}
      />
      <VibeIcon
        onClick={() => onChange((1 / 7) * 6)}
        vibe={(1 / 7) * 6}
        size={iconSize}
        highlight={vibe === (1 / 7) * 6}
      />
      <VibeIcon
        onClick={() => onChange((1 / 7) * 5)}
        vibe={(1 / 7) * 5}
        size={iconSize}
        highlight={vibe === (1 / 7) * 5}
      />
      <VibeIcon
        onClick={() => onChange((1 / 7) * 4)}
        vibe={(1 / 7) * 4}
        size={iconSize}
        highlight={vibe === (1 / 7) * 4}
      />
      <VibeIcon
        onClick={() => onChange((1 / 7) * 3)}
        vibe={(1 / 7) * 3}
        size={iconSize}
        highlight={vibe === (1 / 7) * 3}
      />
      <VibeIcon
        onClick={() => onChange((1 / 7) * 2)}
        vibe={(1 / 7) * 2}
        size={iconSize}
        highlight={vibe === (1 / 7) * 2}
      />
      <VibeIcon
        onClick={() => onChange((1 / 7) * 1)}
        vibe={(1 / 7) * 1}
        size={iconSize}
        highlight={vibe === (1 / 7) * 1}
      />
      <VibeIcon
        onClick={() => onChange(0)}
        vibe={0}
        size={iconSize}
        highlight={vibe === 0}
      />
    </div>
  );
};
