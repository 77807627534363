import styled from "styled-components";

export const Title = styled.h3`
  text-transform: uppercase;
  letter-spacing: 1px;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
  padding: 8px;
  margin-top: 0px;
`;
