import produce from "immer";
import { DraggableLocation } from "react-beautiful-dnd";
import { IAppState, Task } from "../types";
import { parse } from "date-fns";
import { calculateLexicographicalOrdering } from "./calculateLexicographicOrdering";
import * as API from "../api";

/**
 * Move a todo from one day to another. Will change the underlying task's `dateTimeISOUTC`,
 * add a "move" to the task.moves and change `lexicographicalOrdering` -- and then update 
 * `appState`. Returns updated `appState`.
 *
 * @returns updated `appState`
 */
export const moveTodo = (
  appState: IAppState,
  destination: DraggableLocation | undefined,
  source: DraggableLocation,
  draggableId: string,
  upsert: (task: Task) => void = API.upsertTask
) => {
  if (!destination) {
    // Not dropped on a droppable, a way of cancelling a drag.
    return;
  }
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    // Dragged item to its *original* position:
    return;
  }

  const state = produce<IAppState>(appState, (newState) => {
    const start = newState.columns[source.droppableId];
    const task = newState.tasks[start.taskIds[source.index]];
    const finish = newState.columns[destination.droppableId];

    if (start === finish) {
      // dragged within a single column.
      start.taskIds.splice(source.index, 1);
      start.taskIds.splice(destination.index, 0, draggableId);
    } else {
      // Dragged from one column to another.
      start.taskIds.splice(source.index, 1);
      finish.taskIds.splice(destination.index, 0, draggableId);
      
      const to = parse(finish.id, "yyyyMMdd", new Date()).getTime();
      task.moves.unshift({
        createdAt: new Date().getTime(),
        from: task.dateTimeISOUTC,
        to,
      });
      task.dateTimeISOUTC = to;
    }

    // NOTE the lexicographical ordering is completed AFTER the task's position
    // in newState is changed:
    task.lexicographicalOrdering = calculateLexicographicalOrdering(
      newState,
      task.id
    );

    return newState;
  });

  const finish = state.columns[destination.droppableId];
  const task = state.tasks[finish.taskIds[destination.index]];
  upsert(task);

  return state;
};
