import styled from "styled-components";

export const Subtitle = styled.h4`
font-weight: 300;
  letter-spacing: .5px;
  align-self: center;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
`;
