import * as idb from "idb";
import { DBSchema } from "idb";
import { Task } from "../types";
import mudder from "mudder";

// --- Internals

const DATABASE_NAME = "Tasks";
const DATABASE_VERSION = 25;
const OBJECT_TYPE = "Tasks";

/**
 * Schema of "Tasks" indexdb.
 */
interface Schema extends DBSchema {
  Tasks: {
    value: Task;
    key: string;
    indexes: {
      id: string;
      dateTimeISOUTCIdx: number;
      dateTimeISOUTC_lexicographicalOrderingIdx: [number, string];
    };
  };
}

/**
 * A promise that resolves to a indexdb instance. This is where setup and
 * db upgrades and data migrations occur.
 */
const dbPromise = idb.openDB<Schema>(DATABASE_NAME, DATABASE_VERSION, {
  upgrade: async (upgradeDb, oldVersion, _newVersion, tx) => {
    if (!upgradeDb.objectStoreNames.contains(DATABASE_NAME)) {
      const store = upgradeDb.createObjectStore("Tasks", { keyPath: "id" });
      store.createIndex("id", "id", { unique: true });
      store.createIndex("dateTimeISOUTCIdx", "dateTimeISOUTC");
      store.createIndex("dateTimeISOUTC_lexicographicalOrderingIdx", [
        "dateTimeISOUTC",
        "lexicographicalOrdering",
      ]);
    }
    tx.oncomplete = async () => {
      // This is where you can run migrations
      if (oldVersion < 20) {
        console.log("Migrate!");
        const tasks = await upgradeDb
          .transaction(OBJECT_TYPE, "readwrite")
          .objectStore(OBJECT_TYPE)
          .index("dateTimeISOUTCIdx")
          .getAll();
        const taskOrdering = mudder.alphabet.mudder(tasks.length);
        tasks.forEach(async (task, n) => {
          task.lexicographicalOrdering = taskOrdering[n];
          tx.db.put("Tasks", task);
        });
      }

      if (oldVersion < 21) {
        console.log("Migrate 21!");
        const tasks = await upgradeDb
          .transaction(OBJECT_TYPE, "readwrite")
          .objectStore(OBJECT_TYPE)
          .index("dateTimeISOUTCIdx")
          .getAll();
        const increment = 1 / 7;
        tasks.forEach(async (task, n) => {
          if (task.vibe === 0.2) {
            task.vibe = increment * 1;
          }
          if (task.vibe === 0.4) {
            task.vibe = increment * 2;
          }
          if (task.vibe === 0.6) {
            task.vibe = increment * 3;
          }
          if (task.vibe === 0.8) {
            task.vibe = increment * 6;
          }
          tx.db.put("Tasks", task);
        });
      }

      if (oldVersion < 24) {
        console.log("Migrate 24!");
        const tasks = await upgradeDb
          .transaction(OBJECT_TYPE, "readwrite")
          .objectStore(OBJECT_TYPE)
          .index("dateTimeISOUTCIdx")
          .getAll();
        tasks.forEach(async (task, n) => {
          // Alter sizes so they are 0-1 not 0 - 1.4!
          task.size = (1 / 1.4) * task.size;
          // Add ratings and moves arrays:
          // (Items in these arrays are from most createdAt the least recent createdAt)
          // Give each task a rating based on its current size and vibe:
          task.ratings = [
            {
              createdAt: new Date().getTime(),
              vibe: task.vibe,
              size: task.size,
            },
          ];
          // Initialise the moves array to the initial addition of the task (where "from" is undefined):
          task.moves = [
            { createdAt: new Date().getTime(), to: task.dateTimeISOUTC },
          ];
          tx.db.put("Tasks", task);
        });
      }

      if (oldVersion < 25) {
        console.log("Migrate 25!");
        const tasks = await upgradeDb
          .transaction(OBJECT_TYPE, "readwrite")
          .objectStore(OBJECT_TYPE)
          .index("dateTimeISOUTCIdx")
          .getAll();
        tasks.forEach(async (task, n) => {
          // Older tasks should have *two* ratings -- BEFORE and AFTER.
          const rating = {
            createdAt: new Date().getTime(),
            vibe: task.vibe,
            size: task.size,
          }
          if (task.completed && task.ratings.length === 1) {
            task.ratings.push(rating);
          }
          tx.db.put("Tasks", task);
        });
      }
    };

    console.log("Upgrade!");
  },
});

/**
 * Get the first (next) or last (prev) task in the range using
 * `dateTimeISOUTC_lexicographicalOrderingIdx` index.
 * @param range
 * @param direction
 */
const getNextOrPrevious = async (
  range: IDBKeyRange,
  direction: "next" | "prev"
) => {
  const db = await dbPromise;
  let cursor = await db
    .transaction(OBJECT_TYPE, "readwrite")
    .objectStore(OBJECT_TYPE)
    .index("dateTimeISOUTC_lexicographicalOrderingIdx")
    .openCursor(range, direction);

  return cursor?.value;
};

// -- API

/**
 * Get a range of "Task" objects from indexdb based on a IDBKeyRange in indexdb.
 * @param start
 * @param end
 */
export const getByDateRange = async (start: number, end: number) => {
  const dateRange = IDBKeyRange.bound([start, ""], [end, ""]);
  const db = await dbPromise;
  return db
    .transaction(OBJECT_TYPE, "readwrite")
    .objectStore(OBJECT_TYPE)
    .index("dateTimeISOUTC_lexicographicalOrderingIdx")
    .getAll(dateRange);
};

/**
 * Get the first task `after` the given date/time from indexdb.
 */
export const getNextTask = (after: Date) => {
  var afterMs = after.getTime() + 1;
  return getNextOrPrevious(IDBKeyRange.lowerBound([afterMs, ""]), "next");
};

/**
 * Get the first task `before` the given date from indexdb
 */
export const getPreviousTask = (before: Date) => {
  var beforeMs = before.getTime() - 1;
  return getNextOrPrevious(IDBKeyRange.upperBound([beforeMs, ""]), "prev");
};

/**
 * Add or update the given task in indexdb.
 * @param task
 */
export const upsert = async (task: Task) => {
  const db = await dbPromise;
  return db
    .transaction(OBJECT_TYPE, "readwrite")
    .objectStore(OBJECT_TYPE)
    .put(task);
};

/**
 * Delete the given task by id from indexdb.
 * @param id
 */
export const remove = async (id: string) => {
  const db = await dbPromise;
  return db
    .transaction(OBJECT_TYPE, "readwrite")
    .objectStore(OBJECT_TYPE)
    .delete(id);
};
