import React, { memo, useState } from "react";
import styled from "styled-components";

import { Draggable } from "react-beautiful-dnd";
import { Task } from "../types";
import { vibeToColour } from "../util/vibeToColour";
import { vibeToBorder } from "../util/vibeToBorder";
import { VibeIcon } from "./VibeIcon";
import { useDoubleTap } from "../util/useDoubleTap";
import { useAppState } from "../appState";
import { Button } from "./Button";
import { Title } from "./Title";
import { Modal } from "./Modal";
import { EditForm } from "./EditForm";

import Switch from "react-switch";
import { VibeSizeSlider } from "./VibeSizeSlider";
import { VibeSelector } from "./VibeSelector";
import { Subtitle } from "./Subtitle";
import { Subsection } from "./Subsection";

const Container = styled.div<{
  isDragging?: boolean;
  isDragDisabled?: boolean;
  task: Task;
}>`
  border: 0px solid #8b1fb7;
  letter-spacing: 1px;
  border-radius: 10px;
  border: ${(props) =>
    props.task.completed ? "none" : vibeToBorder(props.task.vibe)};
  padding: 12px;
  margin-bottom: 8px;
  min-height: ${(props) => props.task.size * 100}px;
  background-color: ${(props) =>
    props.isDragDisabled ? "lightgrey" : props.isDragging ? "green" : "white"};
  transition: background-color 0.3s ease;
  transition: border 0.5s ease;
  display: flex;
  font-weight: 500;
  background: ${(props) =>
    (props.task.vibe > 0.4 && props.task.vibe <= 0.6) || props.task.completed
      ? "white"
      : vibeToColour(props.task.vibe)};
  display: flex;
  align-items: center;
  font-size: ${(props) => props.task.size * 5 + 10}px;
  transform: rotate(
    ${(props) =>
      props.task.procrastination === 0
        ? 0
        : (Math.random() >= 0.5 ? `-` : ``) +
          `${props.task.procrastination}deg`}
  );
`;

const ContentBlock = styled.span<{ task: Task }>`
  flex: 1;
  display: inline-block;
  word-break: break-word;
  text-decoration: ${(props) =>
    props.task.completed ? "line-through" : "inherit"};
  color: ${(props) => (props.task.completed ? "silver" : "inherit")};
`;

/**
 * Display a task. double click or double tap to edit incomplete tasks.
 */
export const TaskDisplay: React.FC<{
  index: number;
  task: Task;
  columnId: string;
}> = memo(({ index, task, columnId }) => {
  const [isBeingEdited, setIsBeingEdited] = useState(task.new);
  const [isBeingRated, setIsBeingRated] = useState<boolean>(
    task.completed && task.ratings.length === 1
  );
  const [liveTask, setLiveTask] = useState({ ...task });

  const handleDelete = () => {
    dispatch({ type: "deleteTodo", columnId, taskId: task.id });
  };

  const handleToggleComplete = () => {
    dispatch({
      type: "playAudio",
      audio: !task.completed ? { name: "yaay" } : { name: "boo" },
    });

    // The task has not been rated yet ...
    if (!task.completed) {
      // after rating the task will be set to completed unless
      // the user cancels the rating step.
      setIsBeingRated(true);
    }

    // The task is already completed so we mark it complete 
    // and update it (this will reset the task's ratings):
    if (task.completed) {
      dispatch({
        type: "updateTodo",
        ...task,
        taskId: task.id,
        completed: false,
      });
    }
  };

  const handleSizeUpdate = (size: number) => {
    setLiveTask({ ...liveTask, size });
  };

  const handleVibeUpdate = (vibe: number) => {
    setLiveTask({ ...liveTask, vibe });
  };

  const handleContentUpdate = (content: string) => {
    setLiveTask({ ...liveTask, content });
  };

  const handleSave = () => {
    dispatch({
      type: "updateTodo",
      content: liveTask.content,
      size: liveTask.size,
      taskId: task.id,
      vibe: liveTask.vibe,
      completed: false,
    });
    setIsBeingEdited(false);
  };

  const handleAfterRating = () => {
    dispatch({
      type: "afterRateTodo",
      size: liveTask.size,
      taskId: task.id,
      vibe: liveTask.vibe,
    });
    setIsBeingRated(false);
  };

  const handleCancel = () => {
    setLiveTask(task);
    task.new ? handleDelete() : setIsBeingEdited(false);
  };

  const handleCancelRating = () => {
    // TODO: boo sound here is the task is not completed.
    setIsBeingRated(false);
  };

  const { dispatch } = useAppState();
  const doubleTapToEdit = useDoubleTap(() => {
    task.completed
      ? setIsBeingRated(!isBeingRated)
      : setIsBeingEdited(!isBeingEdited);
  });
  const doubleTapToSave = useDoubleTap(handleSave);

  const vibeIconSize = 0.52;

  // FIX: task for rating ought vibe an size ought to the ratings[ratings.length -1];
  // FIX: audio such as {yaayAudio} needs to consistently rendered -- move to app level and trigger with dispatched action that updates app state?

  if (isBeingRated) {
    return (
      <Modal>
        <EditForm>
          <Title>Before</Title>
          <Subsection>
            <Subtitle>
              You anticipated "{task.content}" would have this size and vibe:
            </Subtitle>
          </Subsection>

          <Container task={{ ...task, completed: false }}>
            <VibeIcon vibe={task.vibe} size={task.size} />
            <span
              style={{
                flex: 1,
                display: "inline-block",
                wordBreak: "break-word",
              }}
            >
              {task.content}
            </span>
          </Container>

          <Title>After</Title>
          <Subsection>
            <Subtitle>How did it feel after you'd completed it?</Subtitle>
          </Subsection>

          <Container task={{ ...liveTask, completed: true }}>
            <VibeIcon vibe={liveTask.vibe} size={liveTask.size} />
            <ContentBlock task={{ ...task }}>{liveTask.content}</ContentBlock>
          </Container>

          <Subsection>
            <div style={{ marginTop: 20 }}>
              <VibeSizeSlider
                size={liveTask.size * 100}
                onChange={handleSizeUpdate}
              />
            </div>

            <div style={{ marginTop: 10 }}>
              <VibeSelector
                vibe={liveTask.vibe}
                iconSize={vibeIconSize}
                onChange={handleVibeUpdate}
              />
            </div>

            <Subtitle>
              "{task.content}" was{" "}
              {liveTask.size === task.size
                ? "about the same size"
                : liveTask.size > task.size
                ? `${
                    Math.abs(liveTask.size - task.size) > 0.3 ? "much " : ""
                  }bigger`
                : `${
                    Math.abs(liveTask.size - task.size) > 0.3 ? "much " : ""
                  }smaller`}{" "}
              {(task.size !== liveTask.size && task.vibe !== liveTask.vibe) ||
              (task.size === liveTask.size && task.vibe === liveTask.vibe)
                ? "and"
                : "but"}{" "}
              {liveTask.vibe === task.vibe
                ? "felt as expected"
                : liveTask.vibe > task.vibe
                ? `felt ${
                    Math.abs(liveTask.vibe - task.vibe) > 0.3 ? "much " : ""
                  }better than expected`
                : `felt ${
                    Math.abs(liveTask.vibe - task.vibe) > 0.3 ? "much " : ""
                  }worse than expected`}
              ?
            </Subtitle>
          </Subsection>

          <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 100}}>
             {/* margin at bottom to work aorund Apple's awful action bar */}
            <span>
              <Button small primary onClick={handleAfterRating}>
                Yep!
              </Button>
              <Button small onClick={handleCancelRating}>
                Cancel
              </Button>
            </span>
          </div>
        </EditForm>
      </Modal>
    );
  }

  if (isBeingEdited) {
    return (
      <Modal>
        <Title>{task.new ? "Add Task" : "Edit Task"}</Title>
        <EditForm>
          <Container
            task={liveTask}
            onDoubleClick={handleSave}
            {...doubleTapToSave}
          >
            <VibeIcon vibe={liveTask.vibe} size={liveTask.size} />
            <ContentBlock task={liveTask}>{liveTask.content}</ContentBlock>
          </Container>

          <VibeSizeSlider
            size={liveTask.size * 100}
            onChange={handleSizeUpdate}
          />

          <VibeSelector
            vibe={liveTask.vibe}
            iconSize={vibeIconSize}
            onChange={handleVibeUpdate}
          />

          <div style={{ display: "block" }}>
            <textarea
              rows={3}
              style={{
                display: "block",
                boxSizing: "border-box",
                font: "inherit",
                border: "3px solid #8b1fb7",
                borderRadius: 15,
                padding: 15,
                width: "100%",
                fontSize: "inherit",
              }}
              value={liveTask.content}
              onChange={(e) => handleContentUpdate(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 100 }}>
            {/* margin at bottom to work aorund Apple's awful action bar */}
            <Button small warning onClick={handleDelete}>
              Delete!
            </Button>
            <span>
              <Button small primary onClick={handleSave}>
                Save
              </Button>
              <Button small onClick={handleCancel}>
                Cancel
              </Button>
            </span>
          </div>
        </EditForm>
      </Modal>
    );
  }

  return (
    <Draggable
      draggableId={task.id}
      index={index}
      isDragDisabled={task.completed}
    >
      {(provided, snapshot) => {
        return (
          <Container
            {...provided.draggableProps}
            task={task}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            {...provided.dragHandleProps}
            {...doubleTapToEdit}
          >
            <VibeIcon vibe={task.vibe} size={task.size} />
            <ContentBlock task={task}>{task.content}</ContentBlock>
            <Switch
              onColor="#8b1fb7"
              checked={task.completed}
              onChange={handleToggleComplete}
            />
          </Container>
        );
      }}
    </Draggable>
  );
});
