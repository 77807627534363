import React from "react";

import styled from "styled-components";
import ReactSlider from "react-slider";

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
`;

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #8b1fb7;
  color: #fff;
  border-radius: 50%;
  border-width: 0px;
  cursor: grab;
`;

const Thumb = (props: any, state: any) => (
  <StyledThumb {...props}/>
);

const StyledTrack = styled.div<any>`
  top: 0;
  bottom: 0;
  background: ${props =>
    props.index === 2 ? "#f00" : props.index === 1 ? "white" : "#eee"};
  border-radius: 999px;
`;

const Track = (props: any, state: any) => <StyledTrack {...props} index={state.index} />;

function isNumber(potentialNumber: any): potentialNumber is number {
  return typeof potentialNumber === "number";
}

export const Slider: React.FC<{
  value: number;
  onChange: (value: number) => void;
}> = ({ value, onChange }) => {
  const handleChange = (value: number | number[] | null | undefined) => {
    if (isNumber(value)) {
      onChange(value / 100);
      return;
    }
    throw new Error(
      `Slider returns something other than a number ${JSON.stringify(value)}`
    );
  };

  return (
    <StyledSlider
      defaultValue={value}
      onChange={handleChange}
      renderTrack={Track}
      renderThumb={Thumb}
    />
  );
};
