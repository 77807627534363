import { setUpWeekDates } from "./setUpWeekDates";
import { IAppState } from "../types";

/**
 * Get default/empty data for a given week. Usually shown before API
 * call to get real data resolves.
 */
export const getInitialData = () => {
  const weekDates = setUpWeekDates({
    anyDayInTheWeek: new Date(),
    weekStartsOn: 1
  });
  const initialData: IAppState = {
    loading: true,
    columnOrder: [],
    columns: {},
    tasks: {},
    previousTask: undefined,
    nextTask: undefined,
    ...weekDates
  };
  return initialData;
};
