import styled from "styled-components";

export const Button = styled.button<{
  primary?: boolean;
  warning?: boolean;
  small?: boolean;
}> `
  font-size: ${props => props.small? "14px" : "20px"};
  border-radius: ${props => props.small? "10px" : "15px"};
  ${props => props.small ? "height: 45px;": ""}
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${props => props.small? "10px" : "10px"};
  background: ${props => props.primary ? "#8b1fb7" : props.warning ? "red" : "white"};
  color: ${props => (props.primary || props.warning ? "white" : "black")};
  border: 3px solid #eee;
  margin: 5px;
  cursor: pointer;
`;
