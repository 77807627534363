import produce from "immer";
import { IAppState, Task } from "../types";
import * as API from "../api";

/**
 * Updates a given task. Replaces the existing task in `appState`. Upserts
 * the change to API and returns the updated `appState`.
 *
 * **NOTE**: Sets `new` property of task to `false`.
 */
export const afterRateTodo = (
  appState: IAppState,
  taskId: string,
  size: number,
  vibe: number,
  upsertTask: (task: Task) => void = API.upsertTask
) => {
  return produce<IAppState>(appState, (newState) => {
    const newVersion = { ...newState.tasks[taskId] };

    // If this task has not been rated yet, the first rating
    // is the original, "anticipated" one:
    if (newVersion.ratings.length === 0) {
      newVersion.ratings.push({
        createdAt: new Date().getTime(),
        size: newVersion.size,
        vibe: newVersion.vibe,
      });
    }
    // Add the new rating:
    newVersion.ratings.unshift({ createdAt: new Date().getTime(), size, vibe });

    // Now ratings set, set the current vibe and size:
    newVersion.vibe = vibe;
    newVersion.size = size;
    // When a task is rated it's completion is always true:
    newVersion.completed = true;

    newState.tasks[taskId] = newVersion;
    upsertTask(newVersion);

    return newState;
  });
};
