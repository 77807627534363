/**
 * Takes a vibe number and returns a linear gradient for a task with that vibe.
 */
export function vibeToColour(vibe: number | undefined) {
  if (vibe === undefined ) return "white";
  const grey = vibe * 100;
  let colour = 0;
  if (vibe > 0 && vibe <= 0.2) colour = 80;
  if (vibe > 0.2 && vibe <= 0.4) colour = 190;
  if (vibe > 0.4 && vibe <= 0.6) colour = 198;
  if (vibe > 0.6 && vibe <= 0.8) colour = 55;
  if (vibe > 0.8 && vibe <= 1) colour = 55;
  return `linear-gradient(135deg, hsl(${colour}, ${grey}%, 58%) 0%, hsl(${colour -
    10}, ${grey}%, 58%) 100%)`;
}
