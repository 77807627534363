import styled from "styled-components";
export const Modal = styled.div`
  height: 100%;
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  padding-bottom: 100px; /* ios you suck */
  position: fixed;
  left: 0px;
  top: 0px;
  background: white;
  overflow-y: scroll;
  z-index: 1000000;
`;
