import { IAppState } from "../types";
import * as mudder from "mudder";

/**
 * Based on the `appState`, return the lexicographical ordering
 * to use for the given `taskId`. 
 * 
 * **NOTE**: Remember to use the current appState - e.g. that it
 * reflects that the task was added or moved - so you're working 
 * with the correct task ordering.
 * @param appState
 * @param taskId
 */
export const calculateLexicographicalOrdering = (
  appState: IAppState,
  taskId: string
) => {
  // Get the current ordering of the tasks:
  const taskIds = appState.columnOrder.reduce<string[]>((acc, columnId) => {
    return [...acc, ...appState.columns[columnId].taskIds];
  }, []);
  // Should be be a max of around 70, so perf not a concern.

  // Get the position of the provided taskId in that ordering:
  const idx = taskIds.indexOf(taskId);

  // If the task is not at position 0 in the week then the previous task is the task at idx - 1
  // else the previous task is the previous task provided by AppState 
  const previousTask =
    idx > 0 ? appState.tasks[taskIds[idx - 1]] : appState.previousTask;
  // If the task is not at the last position in the week then the next task is the task at idx + 1
  // else the next task is the next task provided by AppState
  const nextTask =
    idx < taskIds.length - 1 ? appState.tasks[taskIds[idx + 1]] : appState.nextTask;

  console.log(
    "prev",
    previousTask?.lexicographicalOrdering,
    "next",
    nextTask?.lexicographicalOrdering
  );

  return (mudder.base62.mudder(
    previousTask?.lexicographicalOrdering, // if falsy it is replaced by the first symbol,
    nextTask?.lexicographicalOrdering // if falsy replaced by repeating the final symbol several times
  )[0] as any) as string; // mudder is untyped but it does return its results as an array
  // See: https://github.com/fasiha/mudderjs#generate-strings
};
