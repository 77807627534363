import { Task, getTasksWithinDateTimeRange } from "../types";
import {
  getByDateRange,
  remove,
  upsert,
  getPreviousTask,
  getNextTask
} from "./indexDb";

// This is the API used by the rest of the app to store data. 
// Behind the scenes we're using indexDb, but this API could 
// end up being server-based or queue changes for a server too.

/**
 * Get tasks within a given date range.
 */
export const getTasks: getTasksWithinDateTimeRange = async ({ start, end }) => {
  const tasks = await getByDateRange(start.getTime(), end.getTime());
  const previousTask = await getPreviousTask(start);
  const nextTask = await getNextTask(end);

  return {
    tasks,
    previousTask,
    nextTask
  };
};

/**
 * Delete the given task.
 */
export const deleteTask = async (task: Task) => remove(task.id);

/**
 * Update a stored task or insert a new task.
 */
export const upsertTask = async (task: Task) => upsert(task);
