import React, { memo, useState } from "react";
import styled from "styled-components";

import { Droppable } from "react-beautiful-dnd";

import { TaskDisplay } from "./Task";
import { Task } from "../types";
import { vibeToColour } from "../util/vibeToColour";
import { taskVibesToVibe } from "../util/taskVibesToVibe";
import { useAppState } from "../appState";
import { Title } from "./Title";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import UIFx from "uifx";

const Container = styled.div<{ overallVibe?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) =>
    props.overallVibe === undefined
      ? "transparent"
      : vibeToColour(props.overallVibe)};
`;

const TaskList = styled.div<{
  isDraggingOver?: boolean;
  overallVibe: number | undefined;
}>`
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${(props) => (props.isDraggingOver ? "#8b1fb7" : "white")};
  transition: background-color 0.4s ease;
  flex: 1;
`;

const InnerList: React.FC<{ tasks: Task[]; columnId: string }> = memo(
  ({ tasks, columnId }) => {
    return (
      <>
        {tasks.map((task, index) => (
          <TaskDisplay
            key={task.id}
            task={task}
            index={index}
            columnId={columnId}
          />
        ))}
      </>
    );
  }
);

const celebration = new UIFx("/celebration.mp3", {
  volume: 1, // number between 0.0 ~ 1.0
  throttleMs: 100,
});

/**
 * Represents a titled list of tasks you can drag and drop to.
 */
export const Column: React.FC<{
  column: { id: string; title: string };
  tasks: Task[];
  isDropDisabled?: boolean;
}> = memo(({ column, tasks, isDropDisabled }) => {
  const { dispatch } = useAppState();

  const [open, setOpen] = useState(false);

  const columnVibe = taskVibesToVibe(tasks);

  const handleAddTask = () => {
    dispatch({
      type: "createTodo",
      columnId: column.id,
      content: "New Task",
      size: 0.6,
      vibe: 1,
    });
  };

  const handleBirthday = () => {
    setOpen(!open);
    if (!open) {
      celebration.play();
    }
  };

  let isAnnaBirthday = false;

  if (column.title.toLowerCase().indexOf("jun 27th") > 0) {
    const now = new Date();
    if (now.getMonth() === 5 && now.getDate() === 27) {
      isAnnaBirthday = true;
    }
  }

  return (
    <Container overallVibe={columnVibe}>
      <Title style={{ cursor: "pointer" }} onClick={handleAddTask}>
        {column.title}
      </Title>
      {isAnnaBirthday && (
        <div onClick={handleBirthday}>
          <div style={{ textAlign: "center", padding: 20 }}>
            <img alt="get a present!" src="./gift.png" />
          </div>

          <Modal
            styles={{
              modal: { borderRadius: 20, border: "20px solid purple" },
            }}
            open={open}
            onClose={handleBirthday}
            center
          >
            <img
              style={{ width: "100%", maxWidth: 500 }}
              alt="birthday!"
              src={"./birthday-01.png"}
            />
          </Modal>
        </div>
      )}
      <Droppable droppableId={column.id} isDropDisabled={isDropDisabled}>
        {(provided, snapshot) => {
          return (
            <TaskList
              overallVibe={columnVibe}
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {tasks.length === 0 && !snapshot.isDraggingOver && (
                <div
                  style={{ minHeight: 50, cursor: "pointer" }}
                  onClick={handleAddTask}
                />
              )}
              <InnerList tasks={tasks} columnId={column.id} />
              {provided.placeholder}
            </TaskList>
          );
        }}
      </Droppable>
    </Container>
  );
});
