import React from "react";
import { Slider } from "./Slider";
export const VibeSizeSlider: React.FC<{
  onChange: (size: number) => void;
  size: number;
}> = ({ size, onChange }) => {
  return (
    <div>
      <Slider value={size} onChange={onChange} />
    </div>
  );
};
