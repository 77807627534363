/**
 * Takes a vibe number and returns a border for a task with that vibe.
 */
export function vibeToBorder(vibe: number) {
  let colour = "5px solid silver";
  if (vibe > 0 && vibe <= 0.2)
    colour = "5px solid #eee";
  if (vibe > 0.2 && vibe <= 0.4)
    colour = "5px solid #3f95ad";
  if (vibe > 0.4 && vibe <= 0.6)
    colour = "2px dashed silver";
  if (vibe > 0.6 && vibe <= 0.8)
    colour = "5px solid #e6c948";
  if (vibe > 0.8 && vibe <= 1)
    colour = "5px solid #f3cc1f";
  return colour;
}
