import { Task } from "../types";

const meanWeightedByTaskSize = (tasks: Task[]) => {
  const result = tasks
    .map((task, i) => {
      const weight = task.size;
      const sum = task.vibe * weight;
      return [sum, weight];
    })
    .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0]);

  return result[0] / result[1];
};

/**
 * Returns the overall vibe of several tasks using 
 * mean weighted by size.
 */
export function taskVibesToVibe(tasks: Task[]) {
  if (tasks.length === 0) return undefined;
  if (tasks.length === 1) return tasks[0].vibe;

  return Math.round(meanWeightedByTaskSize(tasks) * 100) / 100;
}
